import React from 'react'
import "./components.css"
import LargeHeading from "./LargeHeading"
import { Col, Row } from 'antd';


const Footer = () => {
  return (
    <div className="mt-[50px] footer">
      <div className='page-container'>
        <div className='outer-section pb-[50px]'>
          <div>
            <h1 className="heading-large force-color-inverse text-center">Get In Touch</h1>
          </div>
          <div className='inner-section'>
            <Row gutter={10}>
              <Col xs={24} md={8}>
                <div className='mb-[25px]'>
                  <div className='icon-with-title'>
                    <img src={require(`../assets/at.png`)} width="20" height="20" alt='@' />
                    <p className='text-inverse'>: pps.am@kwglobal.com</p>
                  </div>
                  <div className='icon-with-title'>
                    <img src={require(`../assets/phone.png`)} width="20" height="20" alt='📞' />
                    <p className='text-inverse'>: +1-785-289-1983</p>
                  </div>
                  <div className='icon-with-title'>
                    <img src={require(`../assets/fax.png`)} width="20" height="20" alt='📠' />
                    <p className='text-inverse'>: +1-785-843-6153</p>
                  </div>
                </div>
              </Col>
              <Col xs={24} md={8}>
                <div className='mb-[25px]'>
                  <p className='heading-medium-inverse'>Business Hours: </p>
                  <p className='text-inverse'>Monday-Friday</p>
                  <p className='text-inverse'>8:00am - 4:30pm</p>
                  <p className='text-small-inverse italic'>Central Time (US)</p>
                </div>
              </Col>
              <Col xs={24} md={8}>
                <div className='mb-[25px]'>
                  <p className='heading-medium-inverse'>Postal Address:</p>
                  <p className='text-inverse'>Polymer Processing Society</p>
                  <p className='text-inverse'>C/O KnowledgeWorks Global LTD,</p>
                  <p className='text-inverse'>301 Concourse Blvd, Suite 210,</p>
                  <p className='text-inverse'>Glen Allen, VA 23059</p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer