import React from 'react'


const Member = () => {
    return (
        <div>
            <div className='page-container'>

                {/* about */}
                <div className='outer-section text-center'>
                    <>
                        <h1 className='underline-heading heading-large'>#GROWTHZONE_HEADING#</h1>
                        <div className='underline self-center'></div>
                    </>
                </div>
                <div className='inner-section'>
                    <p>#GROWTHZONE_CONTENT#</p>
                </div>
            </div>
        </div>
    )
}

export default Member