import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import { RecoilRoot } from 'recoil';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import HomePage from './pages/HomePage'
import Header from './components/Header';
import Footer from './components/Footer';
import NoPage from './pages/NoPage';
import Awards from './pages/Awards';
import JamesLWhiteAward from './pages/JamesLWhiteAward';
import Awardees from './pages/Awardees';
import Awardee from './pages/Awardee';
import SocietyInfo from './pages/SocietyInfo';
import PageTemplate from './pages/PageTemplate';
import AwardTemplate from './pages/AwardTemplate';
import NewsLetter from './pages/NewsLetter';
import AwardeeTemplate from './pages/AwardeeTemplate';
import AllAwardees from './pages/AllAwardees';
import Downloads from './pages/Downloads';
import About from './pages/Member';
import Member from './pages/Member';

// axios.defaults.baseURL = 'https://pps-cms.connectedbytes.in/wp-json/';
// axios.defaults.baseURL = 'https://ppsdo.budbeeindia.com/wp-json/';
axios.defaults.baseURL = 'https://cms.tpps.org/wp-json/';



const root = ReactDOM.createRoot(document.getElementById('root'));

// console.log("Designed and Devloped by ConnectedBytes - www.connectedbytes.in");
root.render(
  <RecoilRoot>
    <Router>
      <Header />
      <div className="page-container">
        <Routes>
          {/* hardcoded homepage  */}
          <Route path="/" exact element={<HomePage />} />
          <Route path="/society-info" exact element={<SocietyInfo />} />

          {/* dynamic page template  */}
          <Route path="/member" element={<Member />} />
          <Route path="/:pageSlug" element={<PageTemplate />} />


          <Route path="/awards" element={<Awards />} />

          {/* dynamic single award template */}
          <Route path="/awards/:awardSlug" element={<AwardTemplate />} />
          <Route path="/awards/:awardSlug/awardees" element={<Awardees />} />

          {/* newsletter */}
          <Route path="/newsletter" element={<NewsLetter />} />

          {/* awardees */}
          {/* <Route path="/awardees" element={<Awardees />} /> */}
          <Route path="/awardees" element={<AllAwardees />} />
          <Route path="/awardees/:awardeeSlug" exact element={<AwardeeTemplate />} />


          {/* <Route path="/society-info" element={<SocietyInfo />} /> */}
          {/* <Route path="/james-l-white-innovation-award" element={<JamesLWhiteAward />} /> */}
          {/* <Route path="/awardees" element={<Awardees />} /> */}
          {/* <Route path="/awardees/awardee" element={<Awardee />} /> */}

          <Route path="/downloads" element={<Downloads />} />

          <Route path="*" element={<NoPage />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  </RecoilRoot>
);

// const App = () => {
//   return (
//     <Router>
//         <div className="page-container">
//         <Navbar />
//         <Routes>
//             <Route path="/" exact element={<Home />} />
//             <Route path="/about" element={<About />} />
//         </Routes>
//         <Footer />
//         </div>
//     </Router>
//   );
// }