import React, { useEffect, useState } from 'react';
import { Spin, Input, Pagination } from 'antd';
import { Helmet } from 'react-helmet';
import axios from 'axios';

import LargeHeading from './../components/LargeHeading';
import DownloadsItem from '../components/DownloadsItem';
import Loader from '../components/Loader';

const Downloads = () => {
    const [downloaditems, setDownloaditems] = useState([]);
    const [filtereditems, setFiltereditems] = useState([]);
    const [downloadsloader, setDownloadsloader] = useState(true);
    const [categories, setCategories] = useState([]);
    const [activebutton, setActivebutton] = useState(null);
    const [searchTitle, setSearchTitle] = useState("");
    const [searchAuthor, setSearchAuthor] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 15;

    useEffect(() => {
        fetchDownloadsdata();
        fetchCatgories();
    }, []);

    // Fetch all pages using per_page=25 until no more items are returned
    const fetchDownloadsdata = async () => {
        let page = 1;
        let allData = [];
        try {
            while (true) {
                const response = await axios.get(`wp/v2/pps_downloads?acf_format=standard&per_page=25&page=${page}`);
                if (response.data.length === 0) break;
                allData = [...allData, ...response.data];
                if (response.data.length < 25) break;
                page++;
            }
            setDownloaditems(allData);
            setFiltereditems(allData);
            setDownloadsloader(false);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchCatgories = () => {
        axios.get('wp/v2/download_categories?acf_format=standard&per_page=99')
            .then((response) => {
                setCategories(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    // Helper: apply fuzzy filters (both title and author) to a given dataset
    const applyFilters = (data, title, author) => {
        const filtered = data.filter(item => {
            const titleMatch = item.title.rendered.toLowerCase().includes(title.toLowerCase());
            const authorName = item.acf.author_downloads[0]?.by_name_repeater_downloads || "";
            const authorMatch = authorName.toLowerCase().includes(author.toLowerCase());
            return titleMatch && authorMatch;
        });
        setFiltereditems(filtered);
        setCurrentPage(1); // reset pagination on filter change
    };

    // Update filters when search inputs change; respect current category if set
    useEffect(() => {
        let data = activebutton
            ? downloaditems.filter(item => item.download_categories[0] === activebutton)
            : downloaditems;
        applyFilters(data, searchTitle, searchAuthor);
    }, [searchTitle, searchAuthor, downloaditems, activebutton]);

    const resetCategory = () => {
        setActivebutton(null);
        applyFilters(downloaditems, searchTitle, searchAuthor);
    };

    const filterbyCategory = (value) => {
        setActivebutton(value);
        const catFiltered = downloaditems.filter(item => item.download_categories[0] === value);
        applyFilters(catFiltered, searchTitle, searchAuthor);
    };

    // Calculate current items for the pagination (15 per page)
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filtereditems.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <div>
            {downloadsloader ? <Loader /> :
                <>
                    <Helmet>
                        <title>Downloads - Polymer Processing Society</title>
                    </Helmet>
                    <div className='page-container'>
                        <div className='outer-section text-center'>
                            <LargeHeading title="Downloads" />
                        </div>
                        <div className='inner-section'>
                            {/* Fuzzy search boxes */}
                            <div className="flex flex-col md:flex-row gap-5 mb-[20px]">
                                <div className='flex flex-row gap-5 md:w-1/2'>
                                    <p className='w-1/3 flex items-center text'>Search By Title</p>
                                    <Input
                                        placeholder="Search Title"
                                        value={searchTitle}
                                        onChange={(e) => setSearchTitle(e.target.value)}
                                        className='w-2/3'
                                    />
                                </div>
                                <div className='flex flex-row gap-5 md:w-1/2'>
                                    <p className='w-1/3 flex items-center text'>Search By Author</p>
                                    <Input
                                        placeholder="Search Author"
                                        value={searchAuthor}
                                        onChange={(e) => setSearchAuthor(e.target.value)}
                                        className='w-2/3'
                                    />
                                </div>
                            </div>

                            {/* Download categories tab */}
                            {categories.length === 0 ? <Spin /> :
                                <div className='grid gap-4 mb-[20px] grid-cols-2 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7'>
                                    {categories.map((item, index) => (
                                        <button
                                            className={item.id === activebutton ?
                                                'px-3 py-1 border-solid border-[1px] border-gray-100 rounded brand-bg text-inverse'
                                                :
                                                'px-3 py-1 border-solid border-[1px] border-gray-100 rounded text'}
                                            onClick={() => filterbyCategory(item.id)}
                                            key={index}
                                        >
                                            <p>{item.name}</p>
                                        </button>
                                    ))}
                                    {activebutton !== null &&
                                        <button onClick={resetCategory} className='px-3 py-1 border-solid border-[1px] border-red-400 rounded text'>
                                            Show All
                                        </button>
                                    }
                                </div>
                            }

                            {/* Download list */}
                            {currentItems.length === 0 ?
                                <p className='text-center mt-[50px]'>No items found</p>
                                :
                                <>
                                    {currentItems.map(item => (
                                        <div className='mb-[15px]' key={item.id}>
                                            <DownloadsItem
                                                title={item.title.rendered}
                                                author={item.acf.author_downloads}
                                                link={item.acf.resource_link_downloads}
                                            />
                                        </div>
                                    ))}
                                    <Pagination
                                        current={currentPage}
                                        pageSize={itemsPerPage}
                                        total={filtereditems.length}
                                        onChange={(page) => setCurrentPage(page)}
                                        style={{ textAlign: "center", marginTop: "20px" }}
                                    />
                                </>
                            }
                        </div>
                    </div>
                </>
            }
        </div>
    );
};

export default Downloads